import logoLink from '../resources/sauter.png';
import companyLogoLink from '../resources/sauter.svg';
import footerLogoLink from '../resources/sauter_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#0082C8',
    panel_highlight: '#0082C8',
    chat_background_right: '#D6E9F8',
    chat_background_left: '#FDF5BD',

    active: '#0082C8',
    inactive: '#99C7ED',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#99C7ED',

    phonebook_dropdown: '#99C7ED',
    phonebook_dropdown_svg: '#000',

    primary_light: '#339bd3',
    primary: '#0082C8',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#0082C8',
    text_on_secondary: '#fff',
    focus_active:'#0082C8',

    red: '#D91711',
    green: '#6CC24A',

    connecting: '#0082C8',
    connected: '#6CC24A',
    aborted: '#D91711',

    font_color: 'black',
    link_color: '#4d4d4d',

    font: 'Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#d4d4d4',

    gridbox_header_bg: '#939393',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#0082C8',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'Sauter';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
